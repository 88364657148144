




















































import { Component, Vue } from 'vue-property-decorator'
import { vxm } from '@/store'
import { sendSms } from '@/utils/sms'

@Component
export default class extends Vue {
  private username = ''
  private telephone = ''
  private onResetPwd = false
  private snackbar = false

  private async resetPassword() {
    this.onResetPwd = true
    try {
      const res = await this.axios.patch<{
        username: string
        password: string
      }>(
        `${process.env.VUE_APP_AUTH_URL}/user/password/reset`,
        { username: this.username },
        {
          headers: {
            authorization: `Bearer ${vxm.login.getToken}`,
          },
        }
      )
      await sendSms(
        `รหัสผ่านใหม่ของ\nชื่อผู้ใช้: ${this.username}\nรหัสผ่าน: ${res.data.password}`,
        this.telephone,
        'false'
      )
    } catch {
      this.snackbar = true
    } finally {
      this.onResetPwd = false
      this.username = ''
      this.telephone = ''
    }
  }
}

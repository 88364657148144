
















































import { Component, Vue } from 'vue-property-decorator'
import gql from 'graphql-tag'
import { vxm } from '@/store'

enum AuthRole {
  Admin = 'Admin',
  Normal = 'Normal',
}

@Component
export default class extends Vue {
  private username = ''
  private onChangeRole = false
  private snackbar = false
  private roles = [AuthRole.Normal, AuthRole.Admin]
  private role = AuthRole.Normal

  private async changeRole() {
    this.onChangeRole = true
    try {
      const res = await this.$apollo.query<{ user: { authId: string } }>({
        query: gql`
          query GetUserId($username: String!) {
            user(username: $username) {
              authId
            }
          }
        `,
        variables: {
          username: this.username,
        },
        fetchPolicy: 'no-cache',
      })
      await this.axios.patch(
        `${process.env.VUE_APP_AUTH_URL}/user/${res.data.user.authId}/role`,
        { role: this.role },
        {
          headers: {
            authorization: `Bearer ${vxm.login.getToken}`,
          },
        }
      )
    } catch {
      this.snackbar = true
    } finally {
      this.onChangeRole = false
      this.username = ''
      this.role = AuthRole.Normal
    }
  }
}









import { Vue, Component } from 'vue-property-decorator'
import ResetPassword from '@/components/Management/ResetPassword.vue'
import AuthRole from '@/components/Management/AuthRole.vue'

@Component({
  components: {
    ResetPassword,
    AuthRole,
  },
})
export default class extends Vue {}
